<template>
  <div>
    <div class="header">
      <div class="leftbox">
        <img src="@/assets/img/6pllogo.png" alt="logo" />
        <a href="javascript:;">{{td('注册')}}</a>
      </div>
      <div class="rightbox">
        <!-- <a class="server" href="javascript:;">
          <i class="iconfont icon-kefu"></i>
          <span> 联系客服</span>
        </a> -->
        <router-link class="register" :to="'/'">{{td('登录已有账号')}}</router-link>
		
		<el-select style="width: 120px;margin-right: 0;" v-model="loginForm.l_Name"
			@change="select_language(loginForm.l_Name)">
			<el-option style="font-color: #707070" v-for="state in StatusOptions" :key="state.langCode"
				:value="state.langCode" :label="state.langName" />
		</el-select>
		
      </div>
    </div>
    <div class="main">
      <div class="mainBox">
        <!-- <div class="leftBox">
          <div class="circle"></div>
        </div> -->
        <RegisterForm />
      </div>
    </div>
    <div class="footer">
      <p>
        {{td('六方国际 版权所有')}}
      </p>
    </div>
  </div>
</template>

<script>
	import {
		GetCategoryLangByLangCode,
		GetCaptionLangByLangCode,
	} from "@/api/system/actions";
	import store from "../store/index";
	import Cookies from "js-cookie";
	import {
		getLangCodeOption
	} from "@/api/system/captionLang";
import RegisterForm from './registerForm'
export default {
  name: "register",
  components: { RegisterForm },
  data() {
  	return{
		StatusOptions: [],
		loginForm: {
			l_Name: "",
			username: "",
			password: "",
			rememberMe: false,
			code: "",
			uuid: "",
			ati: "",
			phonenumber: "",
			prefix: "86",
		},
	}
  },
  created() {
    let lang = this.$route.query.lang;
  	this.getDefaultLanguage(lang);
	  this.loginForm.ati = Cookies.get("_ati");
  },
  methods:{
	  getDefaultLanguage(lang) {
	  	//获取语言的方法
	  	getLangCodeOption().then((response) => {
			// console.log(response);
	  		this.StatusOptions = response.data;
	  		this.loginForm.l_Name = lang || this.StatusOptions[0].langCode;
        lang && this.select_language(lang) // 设置语言
	  	});
	  },
	  select_language(value) {
	  	Cookies.set("langId", value);
	  	store.dispatch(GetCategoryLangByLangCode, value);
	  	store.dispatch(GetCaptionLangByLangCode, value);
	  },
  }
};
</script>

<style scoped>
/* 头部 */
.header {
  height: 70px;
  padding: 0px 50px;
  background: #fff;
  z-index: 999;
}
.header .leftbox {
  position: relative;
  width: 340px;
  height: 60px;
  display: inline-block;
  margin-top: 5px;
}
.header .leftbox img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 210px;
}
.header .leftbox a:nth-child(2) {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  color: #ff8228;
  font-size: 20px;
  font-weight: 500;
}
.header .rightbox {
  position: relative;
  width: 250px;
  height: 60px;
  margin-top: 5px;
  display: inline;
  float: right;
  display: flex;
  justify-content: space-between;
}
.header .rightbox .server {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: black;
  font-size: 12px;
}
.header .rightbox .register {
  /* position: absolute; */
  width: 100px;
  height: 40px;
  margin-top: 50%;
  text-align: center;
  line-height: 40px;
  /* right: 60px; */
  /* transform: translateY(-50%); */
  margin: 0 0px 0 20px;
  /* padding: 8px 10px; */
  font-size: 14px;
  color: #fff;
  background: #e60012;
}
/* end头部 */

.main {
  /* background-color: #55C2F1; */
}
.mainBox {
  
  height: calc( 100vh - 120px);
  margin: 0 auto;
  display: flex;
  background: url(../assets/img/SIGN.png) no-repeat ;
  background-size: 100% 100%;
}

.mainBox .leftBox {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle {
  width: 100%;
  height: 550px;
  /* background: url(../assets/img/loginbg.png) no-repeat ; */
}
.footer {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
}
.footer p {
  text-align: center;
}

/* 110% */
@media screen and (max-width: 1800px) {
  .circle {
    transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.85, 0.85);
    -moz-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
  }
  /* .header {
    transform: scale(0.85, 0.85);
    -webkit-transform: scale(0.85, 0.85);
    -moz-transform: scale(0.85, 0.85);
    -o-transform: scale(0.85, 0.85);
  } */
}

/* 110% */
@media screen and (max-width: 1620px) {
  .circle {
    transform: scale(0.75, 0.75);
    -webkit-transform: scale(0.75, 0.75);
    -moz-transform: scale(0.75, 0.75);
    -o-transform: scale(0.75, 0.75);
  }
  /* .header {
    transform: scale(0.75, 0.75);
    -webkit-transform: scale(0.75, 0.75);
    -moz-transform: scale(0.75, 0.75);
    -o-transform: scale(0.75, 0.75);
  } */
}

@media screen and (max-width: 1500px) {
  .circle {
    transform: scale(0.65, 0.65);
    -webkit-transform: scale(0.65, 0.65);
    -moz-transform: scale(0.65, 0.65);
    -o-transform: scale(0.65, 0.65);
  }
  /* .header {
    transform: scale(0.65, 0.65);
    -webkit-transform: scale(0.65, 0.65);
    -moz-transform: scale(0.65, 0.65);
    -o-transform: scale(0.65, 0.65);
  } */
}

@media screen and (max-width: 1380px) {
  .circle {
    transform: scale(0.55, 0.55);
    -webkit-transform: scale(0.55, 0.55);
    -moz-transform: scale(0.55, 0.55);
    -o-transform: scale(0.55, 0.55);
  }
  /* .header {
    transform: scale(0.55, 0.55);
    -webkit-transform: scale(0.55, 0.55);
    -moz-transform: scale(0.55, 0.55);
    -o-transform: scale(0.55, 0.55);
  } */
}

</style>